import Head from './head/header'
import Footer from './footer/home_footer'

import HomeHeader from './head/home_header'
import KemNetLogo from './components/kemnet_logo'
import Dropdown from './components/dropdown'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import { useQuery, gql, useMutation} from '@apollo/client';
import client from '../src/apollo-client';
import { Button } from '@mui/material'
import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators'
import Loader from './components/loader'
import Joyride from 'react-joyride';

import React, { useState } from "react"
import Tour from 'reactour'
import ReactDOM from 'react-dom';
import dynamic from 'next/dynamic'
import Cookies from 'js-cookie'



const inter = Inter({ subsets: ['latin'] })




/*export async function getStaticProps() {

 const { data:products } = await client.query({
      query: gql`
      query MyQuery {
        products {
          name
          id
        }
      }
      `,
    });
    console.log("kjdbkjdbcv"+products);

    return {
      props: {
        products
      },
   };
}

// Define mutation
const publicCustomerCreateMutation = gql`
  mutation createAccount($name: String!, $email: String!, $password: String!) {
    createAccount(email: $email, password: $password) {
     id
    }
  }
`
*/

export function showAlert(){

  alert("You're exiting this product tour")

}



export default function Home({ products }: { products: any[] }) {

  Cookies.remove("mySelectedProducts")

  const steps = [{selector:"#step1", content:"KemNet is a platform that allows you access medications that are shorted or medications that are commercially unavailable. You are also able to access medications that are custom-made to better suit your unique body called Compounds. KemNet is able to connect you with prescribers, depending on the local regulations. "}, {   selector: "#step1", content: "Click to select your role from the options in the drop down", },  ];
    const [isTourOpen, setIsTourOpen] = useState(true);

    const Tour = dynamic(
      () => import('reactour'),
      { ssr: false },
    );

    var isViewedTour = "true"//Cookies.get("viewed_patient_tourr");

  return (
    <>
    

      <Head />
      

  
  <div className="bg-white h-screen w-screen flex flex-col">
   

        
      <HomeHeader />

      <KemNetLogo />

       
      <div className="step1">
      <Dropdown />

      </div>
      

    


      <Footer />

      </div>
    
 

       <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)} />

      
    </>
  )
}
