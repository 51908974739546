
import React, { useState, useRef } from "react";
import {FaAngleUp, FaAngleDown} from "react-icons/fa";
import Link from "next/link";
import Cookies from 'js-cookie';



function Dropdown(){

  const [isOpen, setIsOpen] = useState(false);

  //set default route with useState
  var [nextRoute, setRoute] = useState("customer_dnp/step_1");


    //dropdown items
  var items = [ "Patient","Prescriber", "Pharmacy", "Compounding Lab"];

  const selectedRef = useRef<HTMLDivElement>(null);

  var selection = "Patient";    var text = ""; 

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

  //save selection
  const saveSelection = (event:any) => {

    if (event.target.nodeName === 'DIV') {
     
      text = event.target.textContent;
      if(selectedRef.current) {
        selectedRef.current.textContent = text;
        setIsOpen(!isOpen);

      }

       //save selection  to cookie

       Cookies.set('selectedProfile', text);
       
       if(text==="Pharmacy")
       {setRoute(nextRoute = "pharmacies/signup")}
       
       else if(text=="Compounding Lab"){setRoute(nextRoute = "labs/signup")}
       
       else if(text=="Patient")setRoute(nextRoute = "customer_dnp/step_1")

       else if(text=="Prescriber")setRoute(nextRoute = "prescribers/products_list");
    
      
    }
   
    
  }
  return (
    <>
       <div className="dropdown w-screen  lg:flex justify-center mt-10 px-2 sm:px-2 md:px-3  mb-3 sm:mb-3 md:mb-3">

        <div className="lg:mt-3">I am a</div>
       <div ref={selectedRef} onClick={toggleDropdown} className="flex dropdown-trigger border border-grey_100 cursor-pointer p-3 lg:ml-4   bg-grey_100 rounded-full lg:w-80 w-full sm:w-full md:w-full z-10 sm:z-10">
      { selection } 

       <div className="ml-auto mt-1">

       
      {isOpen && ( <FaAngleUp /> )}
      {!isOpen && ( <FaAngleDown /> )}
       </div>
     
       </div>

       
       <Link href = {nextRoute} >
       <div  className="dropdown-trigger text-white hover:border-green hover:text-grey_600 hover:bg-white border border-white cursor-pointer p-3 text-center  bg-green rounded-full w-32 lg:block lg:ml-1 hidden sm:hidden md:hidden">
      
          Get Started
          </div>
        </Link> 
      
       </div>

     
       {isOpen && (
       <div className="w-screen flex cursor-pointer justify-center lg:mt-0 mt-0 sm:mt-0 md:mt-0 pl-2 sm:pl-2 md:pl-2 pr-2 sm:pr-2 md:pr-2 ">
       <div className="dropdown-list  bg-grey_100 rounded-3xl lg:w-80 w-full sm:w-full md:w-full lg:mr-16">

       {items.map((item, index) => (
        <div
          onClick={saveSelection}
          key={index}
          className={`single-option pt-2 pl-4  pb-3  ${
            index === 0
              ? 'hover:rounded-tr-3xl hover:bg-grey_300 hover:rounded-tl-3xl '
              : index === items.length - 1
              ? 'bg-red-100  hover:rounded-br-3xl hover:rounded-bl-3xl hover:bg-grey_300'
              : 'hover:bg-grey_300'
          }`}
        >
          <div className="text-sm font-medium" >{item}</div>
        
        </div>
      ))}
        
        </div>

        
        

        </div>
       )}
         {//mobile version of the `get started` button
       <>
       <div className="flex justify-center">

       <Link href={nextRoute} >
         <div  className="dropdown-trigger text-white hover:border-green hover:text-grey_600 hover:bg-white border border-white cursor-pointer p-3 text-center  bg-green rounded-full w-32 mt-5 lg:hidden lg:ml-1 ">
         
            Get Started
             </div>
          </Link> 
        
         </div>
         </>
       }
       
    </>
  );
};

export default Dropdown;
