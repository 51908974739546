import Head from 'next/head'

export default function Header(){
    return (
        <>
        <Head>
        <title>KemNet - Pharmaceuticals</title>
        <meta name="description" content="KemNet is a software platform that enables pharmacies to easily order compounded medications from compounding pharmacies in Alberta. KemNet enables pharmacies to keep a transparent regulatory and third-party insurance audit trail. Over time, KemNet enables drug shortage monitoring and resolution.
Compounded medications can only be dispensed upon order by prescriber(s)." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        
        <link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
/>
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>

      </Head>
        </>
    )
}