

import { Inter } from 'next/font/google'
import Link from 'next/link'




const inter = Inter({ subsets: ['latin'] })
export default function HomeHeader(){

    return(
        <>

        <div className='py-5 lg:py-5 md:py-5 sm:py-5  px-5 lg:px-32  md:px-5 sm:px-5 flex lg:flex lg:justify-between  justify-between  '>
<Link href="/">
<div className='text-black2 cursor-pointer hover:bg-grey_100   bg-lightgrey  text-center text-sm rounded-3xl lg:w-20 w-20 py-2 content-start px-2 w-30 md:w-30 sm:w-30 '>
                Home
            </div>
</Link>
          

           <div className="ml-auto flex">

           <Link href="http://accounts.kemnet.ca/">
            <div className='cursor-pointer py-2 mr-4  px-2 bg-orange  lg:w-32 w-30 md:w-20 sm:w-30 text-center text-white  rounded-3xl  text-sm border-white border-2 hover:border-2  hover:border-orange hover:bg-white hover:text-grey_600 '>
               Pharmacy Log In
            </div>
            </Link>

            <Link href="http://accounts.kemnet.ca/">
            <div className='cursor-pointer py-2 content-start px-2 bg-orange  lg:w-32 w-30 md:w-20 sm:w-30 text-center text-white  rounded-3xl text-sm border-white border-2 hover:border-2  hover:border-orange hover:bg-white hover:text-grey_600 '>
               Lab Log In
            </div>
            </Link>

           </div>
          
          
            
           
            

            

        </div>
        
        </>
    )
}

