import Link from "next/link";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer(){

   /* toast.success('Hey! We are currently revamping our platform to improve your experience. Hang tight, we will get better', {
        position: toast.POSITION.BOTTOM_LEFT
    });*/

    return(
        <>

{
   // <ToastContainer />
}
       
       
    <footer className="bg-grey_100 py-3 mt-auto ">

        <div className="footer-policy-section w-full text-center mb-4 text-green">
            <p>Compounded medications require a prescription and medications are only compounded <br></br>and dispensed upon receipt of a valid patient specific prescription</p>

        </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:pl-8 flex justify-between">
      
          <p className="text-grey_600 text-sm ml-16 text-start ">&copy; 2023 Kemet</p>

          <Link href="../faqs">
          <p className="text-grey_600 text-sm ml-auto mr-8 ">FAQ</p>
          </Link>
        

          <Link href="../tos">
          <p className="text-grey_600 text-sm mr-16 text-end  ">Terms of Service</p>
          </Link>
         
     
      </div>
    </footer>
  
       
        </>
    )
}